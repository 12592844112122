import { tns } from "tiny-slider/src/tiny-slider";

var slider = tns({
    container: '.piedra-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    loop: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    mouseDrag: true,
    speed: 1000
});
